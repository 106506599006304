import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Sumana`}</h1>
    <p>{`I have started taking art classes for kids at my house and named the program `}<b>{`ColorfulBlossoms`}</b>{`. My mission is to train interested kids to more advanced art techniques and teach them to properly sketch, color and other necessary artistic skills. I take classes in the weekdays, Monday through Friday evenings.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      