import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Classes`}</h2>
    <ProjectCard title="Basic drawing" link="https://www.instagram.com/p/CAjRfoEnlUJ/?igshid=1d4y2lzzu5ce3" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
I teach the ability to recognize shapes, patterns, edges, lines, angles etc.
    </ProjectCard>
    <ProjectCard title="Pencil sketch" link="https://www.instagram.com/p/CAoUvyYHD9z/?igshid=1hcyxh8yar0rq" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
I use graphite and wooden pencils to teach how to shade and draw different images.
    </ProjectCard>
    <ProjectCard title="Coloring" link="https://www.instagram.com/p/CAjHRfGnKCa/?igshid=bg86dn7xv1rt" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
I teach how to use different kinds of brushes, blend and mix colors.
    </ProjectCard>
    <ProjectCard title="Acrylic paint" link="https://www.instagram.com/p/BwyT7y1AG_-/?igshid=1782msksobran" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
I teach painting with acrylic paints on canvas and mix media.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      