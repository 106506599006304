import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Get in touch`}</h1>
    <p>{`Say `}<a href="http://m.me/sumana.chatterjee.961">{`Hi`}</a>{` to me for any info you need or find my class photos in `}<a href="https://www.instagram.com/sumanam_c/">{`Instagram`}</a>{` and stories in `}<a href="https://www.facebook.com/sumana.chatterjee.961">{`Facebook`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      