import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About me`}</h1>
    <p>{`I am an enthusiastic and encouraging art patron and storyteller with a wide breadth of experience, including the fundamentals of drawing and painting, visual development and concept pitching.`}</p>
    <p>{`I love abstract painting and do that in my free time. I am a stay at home mom and live with my husband and two daughters in Fort Mill, South Carolina.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      